<template>
    <div>
        <v-card class="elevation-4 pa-0" style="border-radius: 7px" >
            <v-card-text class="pa-2" style="font-size: 12px;">
                <v-layout row wrap text-xs-center>
                    <v-flex xs10 sm5 md5 >
                        <v-text-field class="pa-0 mt-3"
                            id="start_target_date"
                            label="Select Start Date"
                            type="date" v-model="startTargetDate"
                            color="teal"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs2 sm1>
                    </v-flex>
                    <v-flex xs10 sm5 md5>
                        <v-text-field class="pa-0 mt-3"
                            id="end_target_date"
                            label="Select End Date"
                            type="date" v-model="endTargetDate"
                            color="teal"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs2 sm1 md1>
                        <v-btn fab primary color="teal darken-2" class="my-3" @click="getCampaignTransactions()" style="height: 30px; width: 30px" >
                            <i class="fas fa-search white--text"> </i>                            
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>

        <div class="mt-4" style="max-height: 400px; overflow: auto; "> 
            <h3 class=" elevation-24 primary darken-2 pa-2 white--text font-weight-bold text-xs-left">({{requestedItems.length}}) Items Bought So Far</h3>
            <v-divider></v-divider>
            <template class="" v-for="item in requestedItems">
                <v-hover :key="item.id">
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 4 : 12}`" :to="'/market_place/items/'+item.product_id" color="white" style="border-left: 5px solid #008080!important">
                        <v-card-text class="pt-2 pb-1" style="font-size: smaller">
                            <h2 class="teal--text">
                                <span class="font-weight-bold teal--text">{{item.product}}</span>
                            </h2> 
                            <v-layout row wrap class="pb-0">
                                <v-flex xs6 text-xs-left>
                                    <span class="dark--text font-weight-bold">Total Cost:</span><span> {{item.price}} FCFA</span>
                                </v-flex>
                                <v-flex xs6 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Quantity Demanded:</span><span> {{item.quantity}}</span>
                                </v-flex>
                                <v-flex xs6 text-xs-left>
                                    <span class="dark--text font-weight-bold">Unit Cost:</span><span> {{item.unit_price}} F CFA</span>
                                </v-flex>
                                <v-flex xs6 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Qty in Stock:</span><span> {{item.quantity_left}}</span>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    <v-divider></v-divider>
                    </v-card>
                </v-hover>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                requestedItems: [],
                startTargetDate: null,
                endTargetDate: null,
            }
        },

        mounted(){
            // executes these after the page has been mounted
            this.getRequestedItems();
            document.title = "PettyCash Market Place | History"
            this.$store.commit('setCloseURL', "/market_place")
        },

        methods:{
            async getRequestedItems(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/market_place/get/user/requested/items/')
                    .then(response => {
                        this.requestedItems = response.data  // get the data and fill into requested_order
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

        },

    }
</script>
